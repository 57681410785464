import { fetchAuthSession } from "aws-amplify/auth";

const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL:', API_URL);

interface FetchOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: BodyInit | null;
  headers?: HeadersInit;
}

export async function fetchAPI(endpoint: string, options: FetchOptions = {}) {
    const session = await fetchAuthSession();
    if (!session || !session.tokens) {
        console.error('No session found');
        return;
    }
    const token = session.tokens.accessToken.toString();
    return fetchAPIInternal(endpoint, options, token);
}


export function fetchAPIInternal(endpoint: string, options: FetchOptions = {}, token?: string) {
  const url = `${API_URL}${endpoint}`;

  // Initialize headers if not already present
  if (!options.headers) {
    options.headers = {};
  }

  // Set default headers and merge with any existing ones
  const defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json',
  };

  // Add the Authorization header if a token is provided
  if (token) {
    defaultHeaders['Authorization'] = `Bearer ${token}`;
  }

  // Merge headers, overwriting defaults with any specified in options.headers
  options.headers = { ...defaultHeaders, ...options.headers };

  // Use fetch to make the HTTP request
  return fetch(url, options)
    .then(response => {
      // Check if the HTTP response is not OK to throw an error
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Assuming server responses are always JSON
    })
    .catch(error => {
      // Log and re-throw the error for further handling
      console.error('Fetch error:', error);
      throw error;
    });
}
