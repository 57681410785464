import React, { useState, useEffect } from 'react';
import { start } from 'repl';

interface TimerComponentProps {
    startedAt: string; // Define the type for the startedAt prop
    canRestart: boolean;
  }

const TimerComponent: React.FC<TimerComponentProps> = ({ startedAt, canRestart }) => {
  const [playTimeLeft, setPlayTimeLeft] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      const totalTime = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
      const now = new Date().getTime();
      const startTime = new Date(startedAt + 'Z').getTime(); // Assume startedAt is in ISO format
      const timeElapsed = now - startTime;
      const timeLeft = totalTime - timeElapsed;
      
      if(timeLeft > 0) {
        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        return `${hours.toString()}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
      } else if (canRestart) {
        return `2h 0m 0s`;
      } else {
        return "No time left. Come back tomorrow! 👍"; // No time left
      }
    };

    // Update playtime left immediately and every second
    setPlayTimeLeft(calculateTimeLeft());
    const intervalId = setInterval(() => {
      setPlayTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [startedAt]);

  return (
    <>
      {playTimeLeft}
    </>
  );
};

export default TimerComponent;
