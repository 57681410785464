import React, { useState } from 'react';
import styles from './CopyToClipboard.module.css';

interface CopyToClipboardProps {
  textToCopy: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ textToCopy }) => {
  const [showAlert, setShowAlert] = useState(false);

  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000); // Hide alert after 3 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
      // Optionally handle error state here
    }
  };

  return (
    <>
      {textToCopy.length > 0 && <button onClick={() => copyTextToClipboard(textToCopy)} className={styles.copyButton} title="Copy to clipboard">
        <i className="bi bi-clipboard"></i>
      </button>
      }
      {showAlert && (
        <div className={`alert ${styles.alertPopup}`} role="alert" style={{position: 'absolute', zIndex: '1'}}>
          Copied to clipboard!
        </div>
      )}
    </>
  );
};

export default CopyToClipboard;
