import React from 'react';
import styles from './Location.module.css';

const regionToFlag = (region: string) => {
  switch (region) {
    case 'us-east-1':
      return <span className={styles.flag}>🇺🇸</span>;
    case 'us-west-1':
      return <span className={styles.flag}>🇺🇸</span>;
    case 'eu-west-1':
      return <span className={styles.flag}>🇮🇪</span>;
    case 'ap-southeast-2':
      return <span className={styles.flag}>🇦🇺</span>;
    default:
      return undefined;
  }
};

const regionToName = (region: string) => {
  switch (region) {
    case 'us-east-1':
      return 'N. Virginia';
    case 'us-west-1':
      return 'N. California';
    case 'eu-west-1':
      return 'Ireland';
    case 'ap-southeast-2':
      return 'Sydney';
    default:
      return undefined;
  }
};

interface LocationProps {
  region: string;
}

const Location: React.FC<LocationProps> = ({ region }) => {
  return (
    <span>
      {regionToFlag(region)}
      {regionToName(region)}
    </span>
  );
};

export default Location;
