export const formatLocalTime = (utcString: string) => {
  const date = new Date(utcString + 'Z'); // Adding 'Z' to indicate UTC
  return new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).format(date);
};